.navbar .dropdown-item img {
    max-height: 20px;
}
/*
.navbar .dropdown-menu {
    background-color: rgba(0,0,0,0.8);
    
}
.navbar .dropdown-menu .dropdown-item {
    color: white;
}
.navbar .dropdown-menu .dropdown-item:hover {
    background-color: rgba(64,64,64,0.8);
}
*/